import React, { useState } from 'react'
import { observer, inject } from 'mobx-react-lite'
import { searchStore } from '/SearchStore.js'

import './Categories.scss'
import * as categories from 'constants/categories'
import Icons from 'components/Map/Icons'

const slug = word => {
  return word
    .toLowerCase()
    .replace(' ', '-')
    .replace('& ', '')
}

function Categories(props) {
  const [isOpen, setOpen] = useState(false)
  const [categoryName, setCategoryName] = useState(null)
  const toggle = val =>
    setOpen(prev => (typeof val === 'boolean' ? val : !prev))
  return (
    <div>
      <a
        className={['refinement-mobile-toggle'].join(' ')}
        onClick={() => toggle()}
      >
        {categoryName ? categoryName : 'Categories...'}
      </a>
      <ul
        className={['refinement-list', isOpen && 'list-open']
          .filter(Boolean)
          .join(' ')}
      >
        {Object.keys(categories.ITEM_CATEGORIES).map(type => {
          const category = `type:${slug(type)}`
          const isSelected = searchStore.hasCategory(category)
          return (
            <li
              key={type}
              className={['refinement-list-item', isSelected && 'selected']
                .filter(Boolean)
                .join(' ')}
              onClick={event => {
                event.preventDefault()
                // toggle dropdown
                toggle(false)

                // de-togglee category on second click
                if (searchStore.hasCategory(category)) {
                  return searchStore.setCategory(null)
                }
                searchStore.setCategory(category)
              }}
            >
              {/* <Icons height={20} type={slug(type)} selected={isSelected} /> */}
              <a className="refinement-list-item-label">{type}</a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
export default observer(Categories)
