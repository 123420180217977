import React from 'react'
import { connectHits, connectStateResults } from 'react-instantsearch-dom'
import ItemSearchDisplay from '../../components/shared/Item/ItemSearchDisplay'

const Hits = connectHits(({ hits, onHoverItem, onLeaveHoverItem }) => {
  return hits.map(hit => (
    <ItemSearchDisplay
      onMouseOver={onHoverItem}
      onMouseOut={onLeaveHoverItem}
      key={hit.id}
      product={hit}
    />
  ))
})

export default connectStateResults(
  ({ searchResults, onHoverItem, onLeaveHoverItem }) =>
    searchResults && searchResults.nbHits !== 0 ? (
      <div className="result-hits" id="results">
        <Hits onHoverItem={onHoverItem} onLeaveHoverItem={onLeaveHoverItem} />
      </div>
    ) : searchResults && searchResults.nbHits == 0 ? (
      <div className="no-results-container">
        <div className="no-results-subcontainer">
          <h4>Sorry, we couldn't find anything.</h4>
          <p>Try widening your search radius to display more results.</p>
          <p>
            On the other hand, you might have spotted an opportunity! Be the
            first to list an item under this criteria
          </p>
        </div>
      </div>
    ) : (
      // add loading icon
      <div className="no-results-container">
        <div className="no-results-subcontainer" />
      </div>
    )
)
