import React from 'react'

import './Marker.css'
import electric_icon from '../../../static/img/electric-icon.png'
import Icons from './Icons'

export default ({ product, selected }) => {
  let { price_d, electric, type } = product
  return (
    <div className="marker-container">
      <div className={`marker ${selected && 'selected'}`}>
        <div className="price-tag">${price_d / 100}</div>
        {electric && (
          <img src={electric_icon} height="20" className="electric-icon" />
        )}
        <Icons type={type} highlight={selected} />
      </div>
    </div>
  )
}
