import React from 'react'
import ReactDOM from 'react-dom'
import {
  GoogleMapsLoader,
  GeoSearch,
  CustomMarker,
  Control,
} from 'react-instantsearch-dom-maps'
import ItemSearchDisplay from 'components/shared/Item/ItemSearchDisplay'
import Marker from 'components/Map/Marker'
import MapStyle from 'components/Map/MapStyle'

export default class ResultsMap extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedProduct: false,
    }
    this.radius_circle = null
  }

  onMapEvent = ({ instance, type, event }) => {
    switch (type) {
      case 'dragend':
      case 'zoom_changed':
        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.props.onMapCenterChanged({
            lat: instance.center.lat(),
            lng: instance.center.lng(),
          })
        }, 0.6 * 1000)
        break
    }
  }

  createInfoWindow = google => {
    if (!this.InfoWindow) {
      this.InfoWindow = new google.maps.InfoWindow()
    }
  }

  onClickMarker = ({ hit, marker }) => {
    if (this.InfoWindow.getMap()) {
      this.InfoWindow.close()
    }
    const node = document.createElement('div')
    node.className = 'infoWindow-wrapper'
    ReactDOM.render(this.renderInfoWindow(hit), node)

    this.InfoWindow.setContent(node)

    this.InfoWindow.open(marker.getMap(), marker)
    this.props.onClickMarker && this.props.onClickMarker({ hit, marker })
  }

  renderInfoWindow = hit => {
    return (
      <ItemSearchDisplay
        product={hit}
        onMouseOver={() => {}}
        onMouseOut={() => {}}
      />
    )
  }

  render() {
    const { className = '' } = this.props
    return (
      <div className={className}>
        <GoogleMapsLoader apiKey="AIzaSyA_0G3ZKX89wmcQdG84SbwYrh0bMwotH7U">
          {google => {
            this.createInfoWindow(google)
            return (
              <GeoSearch
                google={google}
                options={{ styles: MapStyle }}
                initialZoom={12}
                enableRefine={false}
                enableRefineOnMapMove={false}
                onRawEvent={this.onMapEvent}
              >
                {({ hits }) => (
                  <>
                    {/* <Control /> */}
                    {hits.map(hit => (
                      <CustomMarker
                        anchor={{ x: 0, y: -55 }}
                        key={hit.objectID}
                        onClick={({ marker }) =>
                          this.onClickMarker({ hit, marker })
                        }
                        hit={hit}
                      >
                        <Marker
                          key={hit.id}
                          lat={hit._geoloc.lat}
                          lng={hit._geoloc.lng}
                          product={hit}
                          selected={hit.id == this.props.hoveredItemId}
                        />
                      </CustomMarker>
                    ))}
                  </>
                )}
              </GeoSearch>
            )
          }}
        </GoogleMapsLoader>
      </div>
    )
  }
}
