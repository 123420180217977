import React, { useRef, useEffect } from 'react'
import { InstantSearch, ScrollTo } from 'react-instantsearch-dom'
import ItemHits from './ItemHits'
import { searchStore } from '/SearchStore.js'
import Pagination from './Pagination'
import scrollToAnchor from 'utils/scrollToAnchor'

export default function Results(props) {
  function onPageChange(data) {
    // scrollToAnchor(showcase.current, () => {})()
    searchStore.setPage(data.selected)
  }
  return (
    <div>
      <ItemHits
        onHoverItem={props.onHoverItem}
        onLeaveHoverItem={props.onLeaveHoverItem}
      />
      <Pagination onChange={onPageChange} />
    </div>
  )
}
