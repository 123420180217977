import React from 'react'
import { connectStateResults } from 'react-instantsearch-dom'

import './Pagination.scss'
import ReactPaginate from 'react-paginate'
import { searchStore } from 'SearchStore'

export default connectStateResults(
  ({ searchResults, onHoverItem, onLeaveHoverItem, onChange }) =>
    searchResults && (
      <ReactPaginate
        previousLabel={'‹'}
        nextLabel={'›'}
        breakClassName={'break'}
        pageCount={Math.ceil(searchResults.nbPages)}
        forcePage={searchResults.page}
        onPageChange={data => onChange(data)}
        containerClassName={'pagination-list'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    )
)
